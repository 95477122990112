<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.configuration')} / ${$t('menu.notifications')} / ${$t('menu.dashboard')}`">
        <v-card-text>
          <v-row v-if="permisos.includes('dashboard_cards:create') || permisos.includes('dashboard_cards:edit')">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <h3>{{ $t('menu.dashboard') }}</h3>
                <span>
                  {{ $t('dashboard.notification') }}
                </span>
              </v-col>
              <!--CRITICOS-->
              <v-col cols="4">
                <v-card>
                  <v-card-text>
                    <span style="font-size: 20px;">
                      {{ $t('dashboard.notification1') }}
                      <br />
                    </span>
                    <span
                      v-for="(module, i) in modules"
                      :key="i"
                    >
                      <v-checkbox
                        v-if="module.type === 'critical'"
                        v-model="modulesCheck"
                        hide-details
                        class="mt-1"
                        :value="module.slug"
                      >
                        <template #label>
                          <span>{{ module.name }}</span>
                        </template>
                      </v-checkbox>
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
              <!--NECESARIOS-->
              <v-col cols="4">
                <v-card>
                  <v-card-text>
                    <span style="font-size: 20px;">
                      {{ $t('dashboard.notification2') }}
                      <br />
                    </span>
                    <span
                      v-for="(module, i) in modules"
                      :key="i"
                    >
                      <v-checkbox
                        v-if="module.type === 'necesary'"
                        v-model="modulesCheck"
                        hide-details
                        class="mt-1"
                        :value="module.slug"
                      >
                        <template #label>
                          <span>{{ module.name }}</span>
                        </template>
                      </v-checkbox>
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
              <!--IMPORTANT-->
              <v-col cols="4">
                <v-card>
                  <v-card-text>
                    <span style="font-size: 20px;">
                      {{ $t('dashboard.notification3') }}
                      <br />
                    </span>
                    <span
                      v-for="(module, i) in modules"
                      :key="i"
                    >
                      <v-checkbox
                        v-if="module.type === 'important'"
                        v-model="modulesCheck"
                        hide-details
                        class="mt-1"
                        :value="module.slug"
                      >
                        <template #label>
                          <span>{{ module.name }}</span>
                        </template>
                      </v-checkbox>
                    </span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
    <Loading
      v-if="loading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
      },
      model: {},
      showOptions: false,
      loading: false,
      modules: [],
      modulesCheck: [],
      permisos: localStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      optionsChatBotMessages: state => state.app.optionsChatBotMessages,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  created() {
    this.modules.push({
      type: 'critical',
      slug: 'data-general',
      name: 'Datos generales',
    })
    this.modules.push({
      type: 'critical',
      slug: 'dns',
      name: 'Configuración DNS',
    })
    this.modules.push({
      type: 'critical',
      slug: 'markups',
      name: 'Markups',
    })
    this.modules.push({
      type: 'critical',
      slug: 'chatBot',
      name: 'Conversaciones (ChatBot)',
    })
    this.modules.push({
      type: 'critical',
      slug: 'cintillo-create-users',
      name: 'Banner Usuarios y Roles',
    })

    this.modules.push({
      type: 'necesary',
      slug: 'bot-telegram',
      name: 'Bot Telegram',
    })
    this.modules.push({
      type: 'necesary',
      slug: 'products-sin-contrates',
      name: 'Productos sin contratos',
    })
    this.modules.push({
      type: 'necesary',
      slug: 'products-sin-public',
      name: 'Productos sin publicar',
    })
    this.modules.push({
      type: 'necesary',
      slug: 'download-apps',
      name: 'Banner APPs',
    })
    this.modules.push({
      type: 'necesary',
      slug: 'afiliado-pendients',
      name: 'Nuevos afiliados',
    })

    this.modules.push({
      type: 'important',
      slug: 'vencimiento-contrates',
      name: 'Vencimiento de contratos',
    })
    this.modules.push({
      type: 'important',
      slug: 'ventas-totales',
      name: 'Ventas totales',
    })
    this.modules.push({
      type: 'important',
      slug: 'ventas-contrate',
      name: 'Ventas por contratos',
    })
    this.modules.push({
      type: 'important',
      slug: 'moderaciones',
      name: 'Moderaciones',
    })
    this.modules.push({
      type: 'important',
      slug: 'api-deepl-manager',
      name: 'API DeepL - Gestión',
    })
    this.modules.push({
      type: 'important',
      slug: 'api-deepl-count',
      name: 'API DeepL - Gráfico de traducciones',
    })

    this.getItem()
  },
  methods: {
    ...mapMutations([
      'adicionarOptionsMessaje',
      'deleteOptionsMessaje',
      'updateOptionsMessaje',
      'setOnlyShow',
      'setCardsDashboard',
    ]),

    getItem() {
      this.axios
        .get('dashboard_cards', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.model = res.data.data
          this.modulesCheck = this.model.notifications
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },

    save() {
      if (this.modulesCheck.length > 0) {
        this.loading = true
        const json = this.model
        json.notifications = this.modulesCheck

        this.axios
          .post('dashboard_cards', json, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            if (response.data.success === true) {
              this.$toast.success(this.$t('msg.infoSuccess'))
              localStorage.setItem('modulesCheck', JSON.stringify(response.data.data.data.notifications))
              this.setCardsDashboard(response.data.data.data.notifications)

              this.$router.push({ name: 'dashboard', params: { perfil: localStorage.getItem('perfil') } })
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loading = false))
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
